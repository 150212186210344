<template>
  <div class="flex flex-col sm:flex-row justify-between text-sm font-bold">
    <div>
      <div class="flex space-x-4">
        <p class="w-10 flex-shrink-0 text-gray-500 sm:text-right">Alert</p>
        <p class="text-blue-500">{{ alert.alert }}</p>
      </div>
      <div class="flex space-x-4">
        <p class="w-10 flex-shrink-0 text-gray-500 sm:text-right">Rule</p>
        <p class="text-blue-500">{{ alert.rule }}</p>
      </div>
    </div>
    <div class="flex space-x-2">
      <p class="text-gray-500">Evaluation Date</p>
      <p>{{ formatDate(alert.evaluationDate) }}</p>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils';

export default {
  name: 'AlertDialogHeader',
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate,
  },
};
</script>
