const errorMessages = {
  required: 'This field is required',
  email: 'Please enter a valid email',
};

export function getErrorMessage(rule) {
  switch (rule) {
    case 'required': return errorMessages.required;
    case 'email': return errorMessages.email;
    default: return '';
  }
}
