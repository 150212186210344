import Vue from 'vue';
import VueRouter from 'vue-router';
import { getUserToken } from '@/utils';
import { MAINTENANCE_MODE } from '@/constants';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (MAINTENANCE_MODE) {
    to.path === '/maintenance' ? next() : next('/maintenance');
  }

  const userToken = getUserToken();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (to.fullPath === '/login' && userToken) {
    next('/');
  } else if (requiresAuth && !userToken) {
    next('/login');
  } else {
    next();
  }
});

export default router;
