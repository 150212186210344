<template>
  <!-- eslint-disable max-len -->
  <t-modal
    :value="isDialogOpen"
    :disableBodyScroll="true"
    @closed="$emit('close-dialog')"
  >
    <template v-slot:header>
      <AlertDialogHeader :alert="alert" />
    </template>

    <div class="space-y-6">
      <div class="sm:flex sm:justify-between space-y-6 sm:space-y-0">
        <AlertDialogInfo :alert="alert" />
        <AlertDialogLinks
          :alert="alert"
          class="flex-shrink-0"
        />
      </div>
      <AlertDialogTable
        :alert="alert"
        :mismatches="mismatches"
        :aliases="aliases"
      />
      <AlertDialogToggles
        v-if="shouldDisplayToggles"
        :mismatches="mismatches"
        :aliases="aliases"
        :lost-to-agent="lostToAgent"
        :disabled="isReviewed"
        @toggle="handleToggle"
      />
      <AlertDialogComments
        v-model="comments"
        :alert="alert"
      />
      <div class="flex flex-row justify-between">
      <CButton
        v-if="hasManualAutomation"
        class="text-white rounded-md"
        :disabled="shouldDisableReviewButton"
        :is-loading="isMarkingAsReviewed"
        @click="handleMarkAsReviewed(true)"
      >
        {{ applyAutomationButtonText }}
      </CButton>
      <CButton
        class="text-white rounded-md"
        :disabled="shouldDisableReviewButton"
        :is-loading="isMarkingAsReviewed"
        @click="handleMarkAsReviewed(false)"
      >
        {{ reviewButtonText }}
      </CButton>
      </div>
    </div>
  </t-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { CButton } from '@/components/controls';
import { alertTypes } from '@/constants';
import AlertDialogHeader from './AlertDialogHeader.vue';
import AlertDialogInfo from './AlertDialogInfo.vue';
import AlertDialogLinks from './AlertDialogLinks.vue';
import AlertDialogTable from './AlertDialogTable.vue';
import AlertDialogToggles from './AlertDialogToggles.vue';
import AlertDialogComments from './AlertDialogComments.vue';

export default {
  name: 'AlertDialog',
  components: {
    AlertDialogHeader,
    AlertDialogInfo,
    AlertDialogLinks,
    AlertDialogTable,
    AlertDialogToggles,
    AlertDialogComments,
    CButton,
  },
  props: {
    alert: {
      type: Object,
      required: true,
    },
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    applyAutomationButtonText: {
      type: String,
      default: 'Apply Automation',
    },
  },
  data() {
    return {
      agentAsAlias: Boolean(this.alert.setAgentAsAlias),
      brokerageAsAlias: Boolean(this.alert.setBrokerageAsAlias),
      lostToAgent: Boolean(this.alert.setAsLostToAgent),
      comments: this.alert.comment,
      previousComments: this.alert.comment,
      isMarkingAsReviewed: false,
      hasManualAutomation: this.alert.hasManualAutomation,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    alertType: vm => alertTypes[vm.alert.alert],
    isReviewed: vm => Boolean(vm.alert.reviewedDate),
    isCommentUpdated: vm => vm.previousComments !== vm.comments,
    shouldDisplayToggles: vm => vm.alertType === 'B',
    shouldDisableReviewButton() {
      if (this.isReviewed) return !this.isCommentUpdated;
      if (!this.shouldDisplayToggles || this.lostToAgent || this.isCommentUpdated) return false;

      const agentMismatch = this.mismatches.agent && !this.aliases.agent;
      const brokerageMismatch = this.mismatches.brokerage && !this.aliases.brokerage;
      return agentMismatch || brokerageMismatch;
    },
    reviewButtonText: vm => (vm.isReviewed ? 'Update Comment' : 'Mark As Reviewed'),
    mismatches() {
      const { mismatchedAgentName, mismatchedBrokerageName } = this.alert.snapshot;
      const agent = mismatchedAgentName.length ? mismatchedAgentName : null;
      const brokerage = mismatchedBrokerageName.length ? mismatchedBrokerageName : null;
      return { agent, brokerage };
    },
    aliases() {
      return {
        agent: this.agentAsAlias,
        brokerage: this.brokerageAsAlias,
      };
    },
  },
  methods: {
    ...mapActions('alerts', ['markAsReviewed', 'addHiddenAlert']),
    async handleMarkAsReviewed(apply) {
      const payload = {
        id: this.alert.id,
        comment: this.comments,
        setAgentAsAlias: this.aliases.agent,
        setBrokerageAsAlias: this.aliases.brokerage,
        setAsLostToAgent: this.lostToAgent,
        applyAutomation: apply,
      };
      try {
        this.isMarkingAsReviewed = true;
        await this.markAsReviewed(payload);
        if (!this.isReviewed) this.addHiddenAlert(this.alert.id);
        this.$toast.success('Successfully marked as reviewed');
        this.$emit('review');
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.isMarkingAsReviewed = false;
      }
    },
    handleToggle({ name, value }) {
      this[name] = value;
    },
  },
};
</script>
