<template>
  <div class="form-input">
    <slot />
    <p
      v-show="errorMessage"
      class="text-red-500 text-sm"
      v-text="errorMessage"
    />
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    errorMessage: {
      type: String,
      default: null,
    },
  },
};
</script>
