import { authApi, paths } from '@/api';
import {
  getUserToken,
  setUserToken,
  removeUserToken,
} from '@/utils';

const state = {
  user: {},
};

const actions = {
  async login(context, payload) {
    const axiosOptions = { noToken: true };
    const { data } = await authApi.post(paths.USER_LOGIN, payload, axiosOptions);
    setUserToken(data.token);
  },
  async logout() {
    await authApi.post(paths.USER_LOGOUT);
    removeUserToken();
  },
  async getUserDetails({ commit }) {
    const token = getUserToken();
    const params = { token };
    const axiosOptions = { params };
    const { data } = await authApi.get(paths.USER_SESSION, axiosOptions);
    commit('setUser', data.user);
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
