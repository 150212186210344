import moment from 'moment';

export const formatDate = date => moment(date).format('M/DD/YYYY');
export const formatTimeUTC = date => {
  return moment.utc(date).local().format('MMMM D, YYYY h:mm A');
};

export const formatPrice = amount => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};
