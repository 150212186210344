<template>
  <form-input
    :error-message="errorMessage"
    class="mb-3"
  >
    <label
      v-if="label"
      class="mb-1"
      v-text="label"
    />
    <t-input
      v-bind="$attrs"
      :type="type"
      :value="value"
      class="rounded"
      :class="dynamicClasses"
      @input="handleInput"
    />
  </form-input>
</template>

<script>
import FormInput from './FormInput.vue';

export default {
  name: 'CTextInput',
  components: {
    FormInput,
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
  },
  computed: {
    isInvalid() {
      return Boolean(this.errorMessage);
    },
    dynamicClasses() {
      return { 'border-red-500': this.isInvalid };
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
