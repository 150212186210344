import { api, paths } from '@/api';
import {
  ENTRIES_PER_PAGE,
  reviewFilterTypes,
  reviewValueByReviewStatus,
  sessionStorageFilters,
} from '@/constants';

const state = {
  alerts: [],
  hiddenAlertIds: [],
  totalAlerts: 0,
  currentPage: 0,
  totalPages: 1,
  searchFilter: '',
  reviewFilter: '',
  searchQuery: '',
  asaEvaluationDate: '',
  asa: [],
};

const getters = {
  visibleAlerts(state) {
    if (state.reviewFilter === reviewFilterTypes.reviewed) return state.alerts;
    return state.alerts.filter(a => !state.hiddenAlertIds.includes(a.id));
  },
};

const actions = {
  async getAlerts({ state, dispatch }, payload) {
    const { name, endpoint } = payload;
    const params = {
      [name]: state.searchQuery,
      page: state.currentPage,
      pageSize: ENTRIES_PER_PAGE,
      reviewed: reviewValueByReviewStatus[state.reviewFilter],
    };
    if (name === 'agentSuccessAdvisorName') {
      params.evaluationDate = state.asaEvaluationDate;
    }
    const axiosOptions = { params };
    const { data } = await api.get(endpoint, axiosOptions);

    dispatch('setAlertResults', data);
  },
  async getAlertsByEvaluationDate({ dispatch }) {
    const payload = { name: 'evaluationDate', endpoint: paths.ALERTS_BY_EVALUATION_DATE };
    await dispatch('getAlerts', payload);
  },
  async getAlertsByAsa({ dispatch }) {
    const payload = { name: 'agentSuccessAdvisorName', endpoint: paths.ALERTS_BY_ASA_NAME };
    await dispatch('getAlerts', payload);
  },
  async getAlertsByAgentName({ dispatch }) {
    const payload = { name: 'agentName', endpoint: paths.ALERTS_BY_AGENT_NAME };
    await dispatch('getAlerts', payload);
  },
  async getAlertsByClientName({ dispatch }) {
    const payload = { name: 'clientName', endpoint: paths.ALERTS_BY_CLIENT_NAME };
    await dispatch('getAlerts', payload);
  },
  async getSnapshotHistory(context, propertyId) {
    const endpoint = paths.SNAPSHOTS(propertyId);
    const snapshots = await api.get(endpoint);
    return snapshots.data;
  },
  async markAsReviewed(context, payload) {
    const { id, ...body } = payload;
    const endpoint = paths.REVIEW_ALERT(id);
    await api.post(endpoint, body);
  },
  async getASAs({ commit }) {
    const { data } = await api.get(paths.ASA_LIST);
    commit('setAsa', data);
  },

  initializeFilters({ commit }) {
    const storageEntries = Object.entries(sessionStorageFilters);
    storageEntries.forEach(([name, filter]) => {
      const { mutationName, defaultValue } = filter;
      const value = sessionStorage.getItem(name) || defaultValue;
      const commitedValue = filter.isNumber ? +value : value;
      commit(mutationName, commitedValue);
    });
  },
  setAlertResults({ commit }, payload) {
    const { alerts, totalAlerts, totalPages } = payload;
    commit('setAlerts', alerts);
    commit('setTotalAlerts', totalAlerts);
    commit('setTotalPages', totalPages);
  },
  setFilterValue({ commit }, payload) {
    const { name, value } = payload;
    const { mutationName } = sessionStorageFilters[name];
    sessionStorage.setItem(name, value);
    commit(mutationName, value);
  },
  addHiddenAlert({ commit }, id) {
    commit('addHiddenAlert', id);
  },
};

const mutations = {
  setAlerts(state, alerts) {
    state.alerts = alerts;
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  },
  setTotalPages(state, totalPages) {
    state.totalPages = totalPages;
  },
  setTotalAlerts(state, totalAlerts) {
    state.totalAlerts = totalAlerts;
  },
  setSearchQuery(state, searchQuery) {
    state.searchQuery = searchQuery;
  },
  setSearchFilter(state, searchFilter) {
    state.searchFilter = searchFilter;
  },
  setReviewFilter(state, reviewFilter) {
    state.reviewFilter = reviewFilter;
  },
  setAsaEvaluationDate(state, asaEvaluationDate) {
    state.asaEvaluationDate = asaEvaluationDate;
  },
  setAsa(state, asa) {
    state.asa = asa;
  },
  addHiddenAlert(state, hiddenId) {
    state.hiddenAlertIds.push(hiddenId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
