<template>
  <!-- eslint-disable max-len -->
  <span
    v-show="isVisible"
    class="p-3 rounded-md text-white bg-blue-300 cursor-pointer absolute right-7 bottom-20 sm:bottom-5"
    @click="scrollToTop"
  >
    <fa-icon
      icon="arrow-up"
      size="lg"
    />
  </span>
</template>

<script>
export default {
  name: 'ScrollToTop',
  props: {
    viewId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isVisible: false,
    view: null,
  }),
  mounted() {
    this.view = document.getElementById(this.viewId);
    this.view.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.view.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrollToTop() {
      this.view.scrollTop = 0;
    },
    handleScroll() {
      this.isVisible = this.view.scrollTop > 150;
    },
  },
};
</script>
