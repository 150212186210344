<template>
  <div class="divide-y">
    <div class="space-y-5 pb-5">
      <p
        v-if="shouldDisplayChooseTitle"
        class="text-center text-blue-500 font-bold"
      >
        Choose One or Both
      </p>
      <div class="sm:flex sm:justify-center space-y-4 sm:space-y-0 sm:space-x-10">
        <Toggle
          v-if="mismatches.agent"
          :value="aliases.agent"
          :disabled="shouldDisableAliasToggle"
          label="Set Agent As Alias"
          @input="handleToggle('agentAsAlias', $event)"
        />
        <Toggle
          v-if="mismatches.brokerage"
          :value="aliases.brokerage"
          :disabled="shouldDisableAliasToggle"
          label="Set Brokerage As Alias"
          @input="handleToggle('brokerageAsAlias', $event)"
        />
      </div>
    </div>
    <div class="space-y-3 pt-5">
      <p
        v-if="!disabled"
        class="text-center text-blue-500 font-bold"
      >
        Or
      </p>
      <div class="flex justify-center">
        <Toggle
          :value="lostToAgent"
          :disabled="shouldDisableLostToggle"
          label="Set As Lost to Another Agent"
          @input="handleToggle('lostToAgent', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Toggle } from '@/components/controls';

export default {
  name: 'AlertDialogToggles',
  components: {
    Toggle,
  },
  props: {
    mismatches: {
      type: Object,
      required: true,
    },
    aliases: {
      type: Object,
      required: true,
    },
    lostToAgent: Boolean,
    disabled: Boolean,
  },
  computed: {
    shouldDisplayChooseTitle() {
      if (this.disabled) return false;
      const { agent, brokerage } = this.mismatches;
      return agent && brokerage;
    },
    shouldDisableAliasToggle: vm => vm.lostToAgent,
    shouldDisableLostToggle: vm => vm.aliases.agent || vm.aliases.brokerage,
  },
  methods: {
    handleToggle(name, value) {
      if (this.disabled) return;
      this.$emit('toggle', { name, value });
    },
  },
};
</script>
