import isEmpty from 'lodash/isEmpty';

export function getUserToken() {
  return JSON.parse(localStorage.getItem('token'));
}

export function setUserToken(token) {
  const oldToken = getUserToken();
  const matchingToken = token === oldToken;

  if (isEmpty(token) || matchingToken) return;
  localStorage.setItem('token', JSON.stringify(token));
}
export function removeUserToken() {
  return localStorage.removeItem('token');
}
