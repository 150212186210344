<template>
  <!-- eslint-disable max-len -->
  <div class="space-y-3">
    <div class="flex sm:justify-end items-center space-x-3">
      <Spinner
        v-if="isFetchingSnapshots"
        size="6"
      />
      <Toggle
        v-model="previousSnapshotsEnabled"
        label="See Previous Snapshots"
      />
    </div>
    <div class="overflow-y-auto">
      <TTable
        :headers="tableHeaders"
        :data="getTableFields(tableRows)"
        class="text-base"
      >
        <template v-slot:row="{ row, rowIndex, trClass, tdClass }">
          <tr :class="trClass">
            <td
              :class="tdClass"
              v-text="row[0]"
            />
            <td :class="tdClass">
              <span :class="getTableStringStyle(rowIndex, true)">
                {{ row[1] }}
              </span>
            </td>
            <td :class="tdClass">
              <div class="flex items-center">
                <div
                  v-if="getAliasIndicatorStatus(rowIndex)"
                  class="w-3 h-3 flex-shrink-0 bg-green-300 rounded-full mr-2"
                />
                <div>
                  <span v-if="getMismatchString(rowIndex)">
                    <span
                      :class="getTableStringStyle(rowIndex, true)"
                      v-text="getMismatchString(rowIndex)"
                    />
                    <span v-if="row[2]">,</span>
                  </span>
                  <span :class="getTableStringStyle(rowIndex)">
                    {{ row[2] }}
                  </span>
                </div>
              </div>
            </td>
            <td
              v-show="shouldDisplayHistory"
              :class="tdClass"
              v-text="row[3]"
            />
          </tr>
        </template>
      </TTable>
    </div>
    <div
      v-if="shouldDisplayAliasHint"
      class="flex justify-center items-center"
    >
      <div class="w-3 h-3 bg-green-300 rounded-full mr-2" />
      <p class="text-sm text-green-500 font-bold">Alias Matched</p>
    </div>
    <p
      v-if="typeSettings.text"
      class="text-sm font-bold text-center"
      :class="typeSettings.color"
      v-text="typeSettings.text"
    />
    <div
      v-show="shouldDisplayHistory"
      class="flex justify-end items-center space-x-5 text-blue-500"
    >
      <FaIcon
        v-show="currentSnapshotPage > 0"
        class="cursor-pointer"
        icon="chevron-circle-left"
        size="lg"
        @click="currentSnapshotPage--"
      />
      <p class="font-bold select-none">{{ currentSnapshotPage + 1 }}</p>
      <FaIcon
        v-show="currentSnapshotPage < snapshotCount - 1"
        class="transform rotate-180 cursor-pointer"
        icon="chevron-circle-left"
        size="lg"
        @click="currentSnapshotPage++"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { mapActions } from 'vuex';
import { Spinner } from '@/components';
import { Toggle } from '@/components/controls';
import { alertTypes } from '@/constants';
import { formatDate, formatPrice } from '@/utils';
import {
  snapshotTableTypes,
  snapshotTableHeaders,
  snapshotTableFields,
  snapshotTableAliasIndicatorPaths,
} from './constants';

export default {
  name: 'AlertDialogTable',
  components: {
    Spinner,
    Toggle,
  },
  props: {
    alert: {
      type: Object,
      required: true,
    },
    mismatches: {
      type: Object,
      required: true,
    },
    aliases: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    previousSnapshotsEnabled: false,
    currentSnapshotPage: 0,
    snapshots: null,
    isFetchingSnapshots: false,
  }),
  watch: {
    async previousSnapshotsEnabled(value) {
      if (this.snapshots || !value) return;
      this.getSnapshots();
    },
  },
  computed: {
    alertType: vm => alertTypes[vm.alert.alert],
    typeSettings: vm => snapshotTableTypes[vm.alertType],
    isReviewed: vm => Boolean(vm.alert.reviewedDate),
    shouldDisplayHistory: vm => vm.previousSnapshotsEnabled && Boolean(vm.snapshots),
    currentSnapshot: vm => vm.snapshots?.[vm.currentSnapshotPage],
    snapshotCount: vm => vm.snapshots?.length || 0,
    tableHeaders() {
      return this.shouldDisplayHistory ? [...snapshotTableHeaders, 'Snapshot History'] : snapshotTableHeaders;
    },
    tableRows() {
      return snapshotTableFields.map(field => {
        const { property } = field;
        const isMismatch = this.mismatches[property];
        // Snapshot wrapper was created for external path compatibility
        const snapshotWrapper = { snapshot: this.currentSnapshot };
        const internalValue = get(this.alert, field.internalPath);
        const externalValue = get(this.alert, field.externalPath);
        const snapshotValue = get(snapshotWrapper, field.externalPath);

        const valuesArray = [internalValue, externalValue];
        if (this.shouldDisplayHistory) valuesArray.push(snapshotValue);

        const rowData = valuesArray.map((value, colIndex) => {
          if (!value) return 'None';
          if (field.isExternalArray && colIndex > 0) {
            if (value.length) return value.join(', ');
            return isMismatch ? '' : 'None';
          }
          if (field.isDate) return formatDate(value);
          if (field.isPrice) return formatPrice(value);
          return value;
        });
        const fields = [field.label, ...rowData];
        return { property, fields };
      });
    },
    mismatchedStrings() {
      const agent = this.mismatches.agent?.join(', ');
      const brokerage = this.mismatches.brokerage?.join(', ');
      return { agent, brokerage };
    },
    aliasIndicators() {
      const pathEntries = Object.entries(snapshotTableAliasIndicatorPaths);
      return pathEntries.reduce((acc, [label, path]) => {
        acc[label] = get(this.alert, path, false);
        return acc;
      }, {});
    },
    shouldDisplayAliasHint() {
      const indicatorValues = Object.values(this.aliasIndicators);
      return indicatorValues.some(Boolean);
    },
  },
  methods: {
    ...mapActions('alerts', ['getSnapshotHistory']),
    async getSnapshots() {
      const { propertyId } = this.alert.property;
      try {
        this.isFetchingSnapshots = true;
        this.snapshots = await this.getSnapshotHistory(propertyId);
      } catch {
        this.$toast.error('Failed to fetch previous snapshots');
      } finally {
        this.isFetchingSnapshots = false;
      }
    },
    getMismatchString(rowIndex) {
      const { property } = this.tableRows[rowIndex];
      return this.mismatchedStrings[property];
    },
    getAliasIndicatorStatus(rowIndex) {
      const { property } = this.tableRows[rowIndex];
      return this.aliasIndicators[property];
    },
    getTableStringStyle(rowIndex, isMismatch) {
      const { trackedFields } = this.typeSettings;
      const { property } = this.tableRows[rowIndex];
      if (this.isReviewed || !trackedFields.includes(property)) return '';

      switch (this.alertType) {
        case 'A': return 'p-px bg-green-100';
        case 'C': return 'p-px bg-red-100';
        case 'B': {
          if (!isMismatch) return '';
          const isAliasSet = this.aliases[property];
          const hasMismatch = this.mismatches[property];
          return hasMismatch && !isAliasSet ? 'p-px bg-red-100' : '';
        }
        default: return '';
      }
    },
    getTableFields: rows => rows.map(r => r.fields),
  },
};
</script>
