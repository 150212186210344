<template>
  <!-- eslint-disable max-len -->
  <div class="flex flex-col text-center justify-center items-center h-screen text-3xl">
    <FaIcon
      class="text-yellow-600 mb-6"
      icon="search-minus"
      size="lg"
    />
    <h2 class="font-semibold text-gray-800 mb-3">
      Page not found
    </h2>
    <p>
      We can't seem to find the page you're looking for.
    </p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
