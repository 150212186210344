<template>
  <!-- eslint-disable max-len -->
  <div class="flex justify-center">
    <div class="flex flex-1 justify-end">
      <page-button
        v-for="page in previousPages"
        :key="page"
        :page-number="page"
        class="mr-3 bg-blue-200 hover:bg-yellow-200 cursor-pointer"
        @click.native="$emit('select-page', page)"
      />
    </div>
    <page-button
      :page-number="currentPage"
      class="mx-3 sm:mx-5 bg-blue-300 flex-shrink-1"
    />
    <div class="flex flex-1">
      <page-button
        v-for="page in nextPages"
        :key="page"
        :page-number="page"
        class="ml-3 bg-blue-200 hover:bg-yellow-200 cursor-pointer"
        @click.native="$emit('select-page', page)"
      />
    </div>
  </div>
</template>

<script>
import { PageButton } from './controls';

export default {
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
  },
  components: {
    PageButton,
  },
  data: () => ({
    firstPage: 1,
  }),
  computed: {
    previousPages() {
      const pages = this.computePages(this.currentPage, this.firstPage, false);
      if (this.currentPage > 1) {
        pages.unshift(1);
      }
      return pages;
    },
    nextPages() {
      const pages = this.computePages(this.lastPage, this.currentPage, true);
      if (this.currentPage < this.lastPage) {
        pages.push(this.lastPage);
      }
      return pages;
    },
  },
  methods: {
    computePages(highest, lowest, forward) {
      const multiplier = forward ? 1 : -1;
      const diff = highest - lowest - 1;
      if (diff < 1) return [];

      return [this.currentPage + multiplier];
    },
  },
};
</script>
