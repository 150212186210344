import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSignOutAlt,
  faArrowUp,
  faCogs,
  faSearchMinus,
  faChevronCircleLeft,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faSignOutAlt);
library.add(faArrowUp);
library.add(faCogs);
library.add(faSearchMinus);
library.add(faChevronCircleLeft);
library.add(faCommentDots);

Vue.component('FaIcon', FontAwesomeIcon);
