<template>
  <!-- eslint-disable max-len -->
  <div class="flex flex-col text-center justify-center items-center h-screen text-3xl">
    <FaIcon
      class="text-blue-400 mb-6"
      icon="cogs"
      size="lg"
    />
    <h2 class="font-semibold text-gray-800 mb-3">
      We Are Currently Undergoing System Maintenance
    </h2>
    <p>
      We apologize for the inconvenience!
    </p>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
};
</script>
