export default {
  ALERTS_BY_EVALUATION_DATE: '/alerts/evaluationDate',
  ALERTS_BY_ASA_NAME: '/alerts/agentSuccessAdvisor',
  ALERTS_BY_AGENT_NAME: '/alerts/agent',
  ALERTS_BY_CLIENT_NAME: '/alerts/client',
  REVIEW_ALERT: id => `/alerts/${id}/review`,
  ASA_LIST: '/leads/agentSuccessAdvisor',
  USER_LOGIN: '/user/eda/login',
  USER_LOGOUT: '/user/logout',
  USER_SESSION: '/user/session',
  SNAPSHOTS: id => `/snapshots/${id}`,
};
