<template>
  <!-- eslint-disable max-len -->
  <div
    class="flex justify-center"
    :class="{'w-full h-screen items-center': fullscreen}"
  >
    <div
      class="loader ease-linear rounded-full border-gray-200"
      :class="`w-${size} h-${size}`"
    />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    fullscreen: {
      type: Boolean,
      required: false,
    },
    size: {
      type: String,
      default: '8',
    },
  },
};
</script>

<style scoped>
.loader {
  border-top-color: #3b83f6;
  border-width: 3px;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
