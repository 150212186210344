import moment from 'moment';

export const TODAY_FORMATTED = moment(new Date()).format('YYYY-MM-DD');
export const MAINTENANCE_MODE = false;

export const ENTRIES_PER_PAGE = 10;

export const addressParts = [
  { field: 'address' },
  { field: 'unitNumber', prefix: 'unit: ' },
  { field: 'city' },
  { field: 'state', prefix: ', ' },
  { field: 'zip' },
];
