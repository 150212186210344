<template>
  <section class="flex flex-col flex-grow relative">
    <alert-card
      v-for="alert in alerts"
      :key="alert.id"
      :alert="alert"
      v-bind:class="{ 'active': alert.id === lastSelected.id }"
      @click.native="showAlertDialog(alert)"
    />

    <pagination
      v-if="isPaginationShown"
      :currentPage="currentPage + 1"
      :lastPage="totalPages"
      @select-page="changePage"
    />

    <alert-dialog
      v-if="isAlertDialogOpen"
      :alert="selectedAlert"
      :isDialogOpen="isAlertDialogOpen"
      @review="handleAlertReview"
      @close-dialog="hideAlertDialog"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  AlertCard,
  Pagination,
} from '@/components';
import { AlertDialog } from '@/components/dialogs';

export default {
  name: 'AlertResults',
  components: {
    AlertCard,
    AlertDialog,
    Pagination,
  },
  props: {
    alerts: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isAlertDialogOpen: false,
    selectedAlert: {},
    lastSelected: { id: 0 },
  }),
  computed: {
    ...mapState('alerts', ['totalPages', 'currentPage']),
    isPaginationShown() {
      return this.totalPages > 1;
    },
  },
  methods: {
    ...mapActions('alerts', ['setFilterValue']),
    showAlertDialog(alert) {
      this.selectedAlert = alert;
      this.lastSelected = alert;
      this.isAlertDialogOpen = true;
    },
    hideAlertDialog() {
      this.selectedAlert = {};
      this.isAlertDialogOpen = false;
    },
    changePage(page) {
      this.setFilterValue({ name: 'currentPage', value: page - 1 });
      this.$emit('fetch-alerts');
    },
    handleAlertReview() {
      this.hideAlertDialog();
      this.$emit('fetch-alerts');
    },
  },
};
</script>
<style>
.active .absolute.bg-blue-300 {
  background-color: rgba(110,231,183,1);
}
</style>
