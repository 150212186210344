<template>
  <t-select
    v-if="!loading"
    :value="value"
    :options="options"
    @input="$emit('input', $event)"
  />
  <div
    v-else
    class="min-w-xs flex items-center border border-gray-300 rounded px-3"
  >
    <Spinner size="6" />
    <p class="text-sm text-gray-400 pl-2">
      {{ loadingPlaceholder }}
    </p>
  </div>
</template>

<script>
import { Spinner } from '@/components';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    classes: {
      type: String,
      default: '',
    },
    loadingPlaceholder: {
      type: String,
      default: '',
    },
  },
  components: {
    Spinner,
  },
  computed: {
    loading: vm => vm.options.length === 0,
  },
};
</script>
