<template>
  <!-- eslint-disable max-len -->
  <div class="relative flex flex-col md:flex-row overflow-hidden mb-5 p-5 pl-5 sm:pl-10 text-lg md:text-base rounded-lg bg-blue-50 hover:bg-yellow-50 cursor-pointer">
    <div class="absolute bg-blue-300 w-2 h-full top-0 left-0" />
    <div
      v-for="(column, index) in columns"
      :key="index"
      class="md:w-1/3 pl-2"
    >
      <div
        v-for="({ label, value }) in column"
        :key="label"
      >
        <span class="font-bold mr-2">{{ label }}:</span>
        <span v-text="getValue(value)" />
      </div>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { formatDate } from '@/utils';
import { alertCardFields } from './constants';

export default {
  name: 'AlertCard',
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fields() {
      return alertCardFields.map(field => {
        let value = get(this.alert, field.path, '');

        if (value && field.isDate) value = formatDate(value);
        if (!value) value = 'Not Specified';

        return { label: field.label, value };
      });
    },
    columns() {
      const chunk = (arr, chunkSize = 1, cache = []) => {
        const tmp = [...arr];
        if (chunkSize <= 0) return cache;
        while (tmp.length) cache.push(tmp.splice(0, chunkSize));
        return cache;
      };
      return chunk(this.fields, 4);
    },
  },
  methods: {
    getValue(value) {
      if (typeof value === 'object' && value.length === 0) return 'MATCH';
      return typeof value === 'object' ? value.join(', ') : value;
    },
  },
};
</script>
