<template>
  <div>
    <!-- Primary filters -->
    <p class="mb-1">Search By:</p>
    <section class="flex flex-col sm:flex-row justify-between mb-10">
      <div class="flex flex-col xl:flex-row w-full sm:w-80 xl:w-auto">
        <CDropdown
          class="flex-1 mb-5 xl:mb-0 xl:mr-5 h-10"
          :value="searchFilter"
          :options="searchOptions"
          @input="changeSearchFilterType"
        />

        <div class="flex-grow flex-shrink-0 mb-5 xl:mb-0 xl:mr-5 h-10">
          <t-datepicker
            v-if="searchFilter === searchFilterTypes.evaluationDate"
            :value="searchQuery"
            :clearable="false"
            @input="setSearchQuery"
          />
          <CDropdown
            v-if="searchFilter === searchFilterTypes.asa"
            :value="searchQuery"
            :options="asaOptions"
            class="w-full h-10"
            loading-placeholder="Loading..."
            @input="changeAsa"
          />
          <t-input
            v-if="shouldDisplayTextInput"
            class="h-10"
            :value="searchQuery"
            :placeholder="placeholderBySearchFilterType[searchFilter]"
            @input="setSearchQuery"
            @keydown.enter="applyFilter"
          />
        </div>

        <t-datepicker
          v-if="searchFilter === searchFilterTypes.asa"
          class="mb-5 xl:mb-0 xl:mr-5"
          :value="asaEvaluationDate"
          :clearable="false"
          @input="setAsaEvaluationDate"
        />

        <CDropdown
          class="h-10 xl:mr-5 mb-5 sm:mb-0"
          :value="reviewFilter"
          :options="reviewFilterTypes"
          @input="setReviewFilter"
        />
      </div>

      <c-button
        class="text-white sm:w-64 h-10 rounded-md"
        :disabled="shouldDisableSearchButton"
        @click="applyFilter"
      >
        Search
      </c-button>
    </section>

    <section class="flex justify-between sm:justify-end items-center mb-3">
      <p
        v-text="resultCountTitle"
        class="text-2xl font-bold text-gray-700"
      />
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  searchFilterTypes,
  reviewFilterTypes,
  defaultSearchQueryByFilterType,
  defaultFilterValueByFilterName,
} from '@/constants';
import { placeholderBySearchFilterType } from './constants';
import { CButton } from './controls';
import { CDropdown } from './inputs';

export default {
  name: 'AlertFilters',
  components: {
    CDropdown,
    CButton,
  },
  props: {
    isFetchingAlerts: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchFilterTypes,
    searchOptions: Object.values(searchFilterTypes),
    reviewFilterTypes: Object.values(reviewFilterTypes),
    placeholderBySearchFilterType,
    isFetchingAsa: false,
  }),
  computed: {
    ...mapState('alerts', [
      'searchQuery',
      'asaEvaluationDate',
      'searchFilter',
      'reviewFilter',
      'totalAlerts',
      'asa',
    ]),
    shouldDisableSearchButton: vm => vm.shouldDisplayTextInput && !vm.searchQuery,
    shouldDisplayTextInput() {
      const types = [searchFilterTypes.agent, searchFilterTypes.client];
      return types.includes(this.searchFilter);
    },
    resultCountTitle() {
      const counter = this.totalAlerts === 1 ? 'Result' : 'Results';
      return !this.isFetchingAlerts ? `${this.totalAlerts} ${counter}` : '';
    },
    asaOptions() {
      if (!this.asa.length) return this.asa;
      return this.asa.map(({ firstName, lastName }) => `${firstName} ${lastName}`);
    },
  },
  async created() {
    try {
      this.isFetchingAsa = true;
      const isAsa = this.searchFilter === this.searchFilterTypes.asa;
      const storedAsa = sessionStorage.getItem('searchQuery');
      await this.getASAs();
      if (isAsa && !storedAsa) {
        sessionStorage.setItem('searchQuery', this.asaOptions[0]);
      }
    } catch (e) {
      this.$toast.error(e);
    } finally {
      this.isFetchingAsa = false;
    }
  },
  methods: {
    // Vuex methods
    ...mapActions('alerts', ['setFilterValue', 'getASAs']),
    setSearchQuery(value) {
      this.setFilterValue({ name: 'searchQuery', value });
    },
    setAsaEvaluationDate(value) {
      this.setFilterValue({ name: 'asaEvaluationDate', value });
    },
    setReviewFilter(value) {
      this.setFilterValue({ name: 'reviewFilter', value });
    },
    // Component methods
    applyFilter() {
      this.setFilterValue({ name: 'currentPage', value: 0 });
      this.$emit('fetch-alerts');
    },
    updateSearchQuery(filterType) {
      if (filterType === searchFilterTypes.asa) {
        const newSearchQuery = this.asaOptions[0];
        sessionStorage.setItem('searchQuery', newSearchQuery);
        return this.setSearchQuery(newSearchQuery);
      }
      const newSearchQuery = defaultSearchQueryByFilterType[filterType];
      this.setSearchQuery(newSearchQuery);
    },
    changeSearchFilterType(filterType) {
      const { reviewFilter } = defaultFilterValueByFilterName;
      this.setFilterValue({ name: 'searchFilter', value: filterType });
      this.updateSearchQuery(filterType);
      this.setReviewFilter(reviewFilter);
    },
    changeAsa(name) {
      const { asaEvaluationDate } = defaultFilterValueByFilterName;
      this.setSearchQuery(name);
      this.setAsaEvaluationDate(asaEvaluationDate);
    },
  },
};
</script>
