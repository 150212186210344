<template>
  <div class="h-screen flex flex-col overflow-hidden">
    <Header />
    <RouterView class="flex-grow overflow-y-auto" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Header } from '@/components';

export default {
  name: 'PrimaryLayout',
  components: {
    Header,
  },
  created() {
    this.getUserDetails();
  },
  methods: {
    ...mapActions('auth', ['getUserDetails']),
  },
};
</script>
