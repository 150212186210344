<template>
  <!-- eslint-disable max-len -->
  <div class="grid grid-cols-2 gap-y-4 sm:block sm:text-right sm:space-y-2">
    <div
      v-for="field in infoFields"
      :key="field.label"
    >
      <a
        class="text-blue-500 hover:text-white hover:bg-blue-500 font-bold px-2 py-1 rounded-md"
        target="_blank"
        :href="field.value"
        v-text="field.label"
      />
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
import { alertLinkFields } from './constants';

export default {
  name: 'AlertDialogLinks',
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  computed: {
    infoFields() {
      return alertLinkFields.reduce((acc, curr) => {
        const value = get(this.alert, curr.propertyPath, '');
        if (!value) return acc;

        acc.push({ value, ...curr });
        return acc;
      }, []);
    },
  },
};
</script>
