import { TODAY_FORMATTED } from './global';

export const searchFilterTypes = {
  evaluationDate: 'Evaluation Date',
  asa: 'Agent Success Advisor',
  agent: 'Agent Name',
  client: 'Client Name',
};

export const reviewFilterTypes = {
  all: 'All',
  reviewed: 'Reviewed',
  notReviewed: 'Not Reviewed',
};

export const reviewValueByReviewStatus = {
  All: null,
  Reviewed: true,
  'Not Reviewed': false,
};

export const defaultSearchQueryByFilterType = {
  [searchFilterTypes.evaluationDate]: TODAY_FORMATTED,
  [searchFilterTypes.asa]: '',
  [searchFilterTypes.agent]: '',
  [searchFilterTypes.client]: '',
};

export const defaultFilterValueByFilterName = {
  searchFilter: searchFilterTypes.evaluationDate,
  reviewFilter: reviewFilterTypes.notReviewed,
  searchQuery: defaultSearchQueryByFilterType[searchFilterTypes.evaluationDate],
  asaEvaluationDate: defaultSearchQueryByFilterType[searchFilterTypes.evaluationDate],
};

export const sessionStorageFilters = {
  searchQuery: {
    mutationName: 'setSearchQuery',
    defaultValue: defaultFilterValueByFilterName.searchQuery,
  },
  asaEvaluationDate: {
    mutationName: 'setAsaEvaluationDate',
    defaultValue: defaultFilterValueByFilterName.asaEvaluationDate,
  },
  searchFilter: {
    mutationName: 'setSearchFilter',
    defaultValue: defaultFilterValueByFilterName.searchFilter,
  },
  reviewFilter: {
    mutationName: 'setReviewFilter',
    defaultValue: defaultFilterValueByFilterName.reviewFilter,
  },
  currentPage: {
    mutationName: 'setCurrentPage',
    defaultValue: 0,
    isNumber: true, // Flag to avoid converting to string in session storage
  },
};
