<template>
  <div class="space-y-2">
    <p class="font-bold">Comments</p>
    <TTextarea
      :value="value"
      @input="handleInput"
    />
    <div
      v-if="isReviewed"
      class="flex items-center space-x-2"
    >
      <fa-icon
        class="text-blue-500"
        icon="comment-dots"
        size="lg"
      />
      <p class="text-blue-500 font-bold">
        Reviewed <span v-if="reviewerName">by {{ reviewerName }}</span>
        on {{ formatTimeUTC(reviewedDate) }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatTimeUTC } from '@/utils';

export default {
  name: 'AlertDialogComments',
  props: {
    alert: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    reviewerName: vm => vm.alert.reviewerName,
    reviewedDate: vm => vm.alert.reviewedDate,
    isReviewed: vm => Boolean(vm.reviewedDate),
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value || null);
    },
    formatTimeUTC,
  },
};
</script>
