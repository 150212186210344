<template>
  <div class="flex flex-col space-y-3">
    <div
      v-for="field in infoFields"
      :key="field.label"
      class="flex items-center space-x-2"
    >
      <p class="w-32 flex-shrink-0 text-gray-500 text-sm font-bold">{{ field.label }}</p>
      <p>{{ field.value }}</p>
    </div>
  </div>
</template>

<script>

import { formatDate, getFieldFromPath, getAddressFromFields } from '@/utils';
import { alertInfoFields } from './constants';

export default {
  name: 'AlertDialogInfo',
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  computed: {
    infoFields() {
      return alertInfoFields.map(field => {
        let value = getFieldFromPath(this.alert, field.propertyPath, '');
        if (value && field.isDateTime) value = formatDate(value);
        if (!value) value = 'Not Specified';
        else if (field.label.toLowerCase() === 'address') {
          value = getAddressFromFields(this.alert?.property);
        }
        return { label: field.label, value };
      });
    },
  },
};
</script>
