<template>
  <!-- eslint-disable max-len -->
  <div class="flex flex-shrink-0 justify-between items-center bg-white h-20 px-5 py-2 sm:py-3 shadow-md">
    <img
      class="w-48 sm:w-64 cursor-pointer"
      src="@/assets/images/ia-logo.png"
    />
    <section class="flex items-center">
      <p class="font-bold text-gray-700 mr-5 hidden sm:block">
        {{ user.firstName}} {{ user.lastName }}
      </p>
      <img
        class="w-8 h-8 sm:w-10 sm:h-10 rounded-full mr-5"
        src="https://static.idealagent.com/persist/Blank.jpg"
      />
      <fa-icon
        class="text-blue-500 hover:text-blue-400 cursor-pointer"
        icon="sign-out-alt"
        size="lg"
        title="Sign out"
        @click="handleLogout"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Header',
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {
    ...mapActions('auth', ['logout']),
    async handleLogout() {
      try {
        sessionStorage.clear();
        await this.logout();
        this.$router.push('/login');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
