import { searchFilterTypes } from '@/constants';

export const alertCardFields = [
  {
    label: 'Evaluation Date',
    path: 'evaluationDate',
    isDate: true,
  },
  {
    label: 'Name',
    path: 'property.name',
  },
  {
    label: 'Address',
    path: 'property.address',
  },
  {
    label: 'Property Status',
    path: 'property.propertyStatus',
  },
  {
    label: 'Alert',
    path: 'alert',
  },
  {
    label: 'Rule',
    path: 'rule',
  },
  {
    label: 'Exchange Status',
    path: 'property.exchangeStatus',
  },
  {
    label: 'Exchange Date',
    path: 'property.exchangeDate',
    isDate: true,
  },
  {
    label: 'Our Agent',
    path: 'property.assignedAgentName',
  },
  {
    label: 'Web Agent',
    path: 'snapshot.mismatchedAgentName',
  },
  {
    label: 'Our Brokerage',
    path: 'property.assignedAgentBrokerage',
  },
  {
    label: 'Web Brokerage',
    path: 'snapshot.mismatchedBrokerageName',
  },
];

export const placeholderBySearchFilterType = {
  [searchFilterTypes.agent]: 'Enter agent name',
  [searchFilterTypes.client]: 'Enter client name',
};
