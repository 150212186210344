import { PrimaryLayout } from '@/layouts';

const primaryRoutes = [
  {
    path: '/',
    name: 'Alerts',
    component: async () => (await import(/* webpackChunkName: "alerts" */ '@/views')).Alerts,
  },
];

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: async () => (await import(/* webpackChunkName: "login" */ '@/views')).Login,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: async () => (await import(/* webpackChunkName: "maintenance" */ '@/layouts')).Maintenance,
  },
  {
    path: '/',
    component: PrimaryLayout,
    children: primaryRoutes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: async () => (await import(/* webpackChunkName: "notFound" */ '@/layouts')).NotFound,
  },
];

export default routes;
