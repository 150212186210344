export const alertInfoFields = [
  {
    label: 'Client Name',
    propertyPath: 'property.name',
  },
  {
    label: 'Exchange Date',
    propertyPath: 'property.exchangeDate',
    isDateTime: true,
  },
  {
    label: 'Address',
    propertyPath: 'property.address',
  },
  {
    label: 'Exchange Status',
    propertyPath: 'property.exchangeStatus',
  },
];

export const alertLinkFields = [
  {
    label: 'Connect Link',
    propertyPath: 'property.connectLink',
  },
  {
    label: 'Zillow Link',
    propertyPath: 'property.zillowLink',
  },
  {
    label: 'Realtor Link',
    propertyPath: 'property.realtorLink',
  },
  {
    label: 'Agent\'s Team',
    propertyPath: 'property.agentProfileLink',
  },
];

export const snapshotTableTypes = {
  A: {
    text: 'Internal Data Updated',
    color: 'text-green-500',
    trackedFields: ['propertyStatus'],
  },
  B: {
    trackedFields: ['agent', 'brokerage'],
  },
  C: {
    text: 'Follow up to see why property went off market',
    color: 'text-red-500',
    trackedFields: ['propertyStatus'],
  },
};

export const snapshotTableHeaders = ['Snapshot', 'Internal', 'External'];
export const snapshotTableFields = [
  {
    label: 'Date',
    property: 'evaluationDate',
    internalPath: 'evaluationDate',
    externalPath: 'snapshot.dtCreated',
    isDate: true,
  },
  {
    label: 'Agent',
    property: 'agent',
    internalPath: 'property.assignedAgentName',
    externalPath: 'snapshot.agentName',
    isExternalArray: true,
  },
  {
    label: 'Brokerage',
    property: 'brokerage',
    internalPath: 'property.assignedAgentBrokerage',
    externalPath: 'snapshot.brokerageName',
    isExternalArray: true,
  },
  {
    label: 'Property Status',
    property: 'propertyStatus',
    internalPath: 'property.propertyStatus',
    externalPath: 'snapshot.displayStatus',
    isExternalArray: true,
  },
  {
    label: 'Active List Date',
    property: 'activeListingDate',
    internalPath: 'property.activeListingDate',
    externalPath: 'snapshot.activeListDate',
    isDate: true,
  },
  {
    label: 'List Price',
    property: 'listPrice',
    internalPath: 'property.listPrice',
    externalPath: 'snapshot.listPrice',
    isPrice: true,
  },
  {
    label: 'Sale Price',
    property: 'soldPrice',
    internalPath: 'property.soldPrice',
    externalPath: 'snapshot.soldPrice',
    isPrice: true,
  },
  {
    label: 'Sale Date',
    property: 'soldDate',
    internalPath: 'property.soldDate',
    externalPath: 'snapshot.lastSoldOn',
    isDate: true,
  },
];

export const snapshotTableAliasIndicatorPaths = {
  agent: 'snapshot.isAgentAliasMatched',
  brokerage: 'snapshot.isBrokerageAliasMatched',
};
