import { addressParts } from '@/constants/global';

export const getAddressFromFields = obj => {
  const getFieldString = (o, index) => {
    const space = index !== 0 ? ' ' : '';
    const pre = o?.prefix ? `${o.prefix}` : space;
    return obj[o.field] ? `${pre}${obj[o.field]}` : '';
  };
  if (obj?.address.split(',').length > 2) return obj.address;
  return addressParts.reduce((a, cv, i) => (a || '') + getFieldString(cv, i), '');
};
