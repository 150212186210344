import axios from 'axios';
import { getUserToken } from '@/utils';

const envBaseUrl = process.env.VUE_APP_BASE_URL;
const envConnectBaseUrl = process.env.VUE_APP_CONNECT_URL;

const api = axios.create({
  baseURL: envBaseUrl || 'https://dev-audit-api.idealagent.com',
});

api.interceptors.request.use((config) => {
  if (config.noToken) return config;

  const localConfig = { ...config };
  const userToken = getUserToken();
  if (userToken) {
    localConfig.headers.common.token = userToken;
  } else {
    delete localConfig.headers.common.token;
  }
  return localConfig;
});

api.interceptors.response.use(null, error => {
  throw new Error(error.response.data.displayedMessage);
});

const authApi = axios.create({
  baseURL: envConnectBaseUrl || 'https://dev-connect-api.idealagent.com',
});

authApi.interceptors.request.use((config) => {
  if (config.noToken) return config;

  const localConfig = { ...config };
  const userToken = getUserToken();
  if (userToken) {
    localConfig.headers.common.token = userToken;
  } else {
    delete localConfig.headers.common.token;
  }
  return localConfig;
});

authApi.interceptors.response.use(null, error => {
  throw new Error(error.response.data.displayedMessage);
});

export { api, authApi };
