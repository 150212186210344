<template>
  <!-- eslint-disable max-len -->
  <t-button
    v-bind="$attrs"
    class="flex justify-center items-center"
    @click="handleClick"
  >
    <spinner
      v-if="isLoading"
      size="4"
    />
    <template v-else>
      <fa-icon
        v-if="icon"
        :icon="icon.name"
        :size="icon.size"
      />
      <slot v-else />
    </template>
  </t-button>
</template>

<script>
import { Spinner } from '@/components';

export default {
  name: 'CButton',
  components: {
    Spinner,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleClick() {
      if (this.isLoading) return;

      this.$emit('click');
    },
  },
};
</script>
