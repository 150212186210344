<template>
  <!-- eslint-disable max-len -->
  <div
    class="flex items-center space-x-3 cursor-pointer"
    :class="{ 'opacity-25 cursor-not-allowed': disabled }"
    @click="handleClick"
  >
    <p
      v-if="label"
      class="text-gray-500 select-none"
      v-text="label"
    />
    <div :class="[toggleBackgroundColor, 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
      <span :class="[value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: Boolean,
  },
  computed: {
    toggleBackgroundColor() {
      if (this.value) return 'bg-blue-500';
      return this.disabled ? 'bg-gray-200' : 'bg-blue-200';
    },
  },
  methods: {
    handleClick() {
      if (this.disabled) return;
      this.$emit('input', !this.value);
    },
  },
};
</script>
