<template>
  <!-- eslint-disable max-len -->
  <div class="flex justify-center items-center w-9 h-9 rounded-md">
    <p
      class="font-bold text-white"
      v-text="pageNumber"
    />
  </div>
</template>

<script>
export default {
  name: 'PageButton',
  props: {
    pageNumber: {
      type: Number,
      required: true,
    },
  },
};
</script>
